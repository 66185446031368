<template>
  <div class="card-v2 mx-auto" :style="{ backgroundColor }">
    <slot name="card-heading">
      <h5 class="fw-bold card-v2__heading mb-4" v-if="heading">
        {{ heading }}
      </h5>
    </slot>
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    heading: {
      type: String,
    },
    backgroundColor: {
      type: String,
      default: "#fff",
    },
  },
};
</script>

<style scoped lang="scss">
.card-v2 {
  background: #ffffff;
  border: 1.5px solid #ebebeb;
  border-radius: 5px;
  padding: 2rem;
  &__heading {
    font-size: 1.25rem;
  }
}
</style>
