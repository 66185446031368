<template>
  <div>
    <Modal
      modalId="digid-data"
      :no-close-on-backdrop="false"
      :hide-header="true"
      :hide-footer="true"
    >
      <div class="whitespace-pre">
        <img src="../../../assets/images/digid.png" class="my-4" alt="" />
        <div class="row">
          <div class="col-md-12">
            <strong>{{ $t("Personal data") }}</strong>
          </div>
        </div>
        <div
          class="row mt-1 text-sm"
          v-for="(value, key) in tenant.digid_data.personal"
          :key="`${key}${Date.now}`"
        >
          <div class="col-md-5 text-gray-500">{{ key }}</div>
          <div class="col-md-7 font-semibold">{{ value }}</div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <strong>{{ $t("Adres historie") }}</strong>
          </div>
        </div>
        <div
          class="mt-6 border-b pb-5"
          v-for="(addresses, key1) in tenant.digid_data.address"
          :key="key1"
        >
          <div v-if="addresses.address && addresses.address.length">
            <div
              class="row mt-1 text-sm"
              v-for="(address, key) in addresses.address"
              :key="key"
            >
              <div class="col-md-5 text-gray-500">{{ address.key }}</div>
              <div class="col-md-7 font-semibold">{{ address.value }}</div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <strong>{{ $t("Income") }}</strong>
          </div>
        </div>
        <div
          class="row mt-1 text-sm"
          v-for="(value, key) in tenant.digid_data.financial"
          :key="key"
        >
          <div class="col-md-5 text-gray-500">
            {{ value.split(":")[0].trim() }}
          </div>
          <div class="col-md-7 font-semibold">
            {{ value.split(":")[1].trim() }}
          </div>
        </div>
      </div>
    </Modal>
    <Modal
      modalId="digid-uwv-data"
      :no-close-on-backdrop="false"
      :hide-header="true"
      :hide-footer="true"
    >
      <div class="whitespace-pre">
        <img src="../../../assets/images/digid_uwv_logo.png" class="my-4" alt="" />
        <div class="row">
          <div class="col-md-12">
            <strong>{{ $t("UWV information") }}</strong>

            <div class="font-bold">{{ $t("Employer") }}</div>
            <div class="text-gray-500">{{ tenant.uwv_data.agency }}</div>
            <div class="font-bold mt-4">{{ $t("Recent income:") }}</div>
          </div>
        </div>
        <div class="text-gray mt-1">
          <div class="row" v-for="(income, key2) in tenant.uwv_data.income" :key="key2">
            <div class="col-md-12">
              <div>{{ $t("Period") }}: {{ income.period }}</div>
              <div>{{ $t("Hours") }}: {{ income.hours }}</div>
              <div>{{ $t("Wages") }}: {{ income.wages }}</div>
              <div>{{ $t("Car contribution") }}: {{ income.car_contribution }}</div>
              <div>Value: {{ income.value }}</div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import Modal from "../../../components/ui/Modal.vue";

export default {
  name: "DigidDataModal",
  props: ["tenant"],
  components: {
    Modal,
  },
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>
