<template>
  <div class="container">
    <template v-if="!getLoadings.assigning && listing">
      <div class="d-flex justify-content-between">
        <h2 class="fw-bold heading text-black mb-4" v-if="tenant.name">
          {{ $t("Tenant") + ":" + tenant.name }}
        </h2>
        <div>
          <router-link
            class="btn btn-white"
            :to="{ name: 'ListingAssigned', params: { listingId: listing.id } }"
          >
            {{ $t("Back to overview") }}
          </router-link>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <CardV2>
            <div class="row">
              <div class="col-md-12">
                <strong>
                  {{ $t("My personal details") }}
                </strong>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                {{ $t("Firstname(s)") }}
              </div>
              <div class="col-md-6 text-right fw-bold">
                {{ tenant.first_name }}
              </div>
              <div class="col-md-6">
                {{ $t("Lastname") }}
              </div>
              <div class="col-md-6 text-right fw-bold">
                {{ tenant.last_name }}
              </div>

              <div class="col-md-6">
                {{ $t("Date of birth") }}
              </div>
              <div class="col-md-6 text-right fw-bold">
                {{ tenant.dob | moment("DD-MM-YYYY") }}
              </div>

              <div class="col-md-6">
                {{ $t("Phone Number") }}
              </div>
              <div class="col-md-6 text-right fw-bold">
                {{ tenant.phone_number }}
              </div>

              <div class="col-md-6">
                {{ $t("Email address") }}
              </div>
              <div class="col-md-6 text-right fw-bold">
                {{ user.email }}
              </div>

              <div class="col-md-6">
                {{ $t("Address") }}
              </div>
              <div class="col-md-6 text-right fw-bold">
                {{ tenant.address }}
                {{ tenant.houseNr }}
                {{ tenant.zipcode }}
                {{ tenant.city ? tenant.city : "" }}
              </div>

              <div class="col-md-6">
                {{ $t("Net income") }}
              </div>
              <div class="col-md-6 text-right fw-bold">€{{ tenant.net_income }}</div>

              <div class="col-md-6">
                {{ $t("Gross income") }}
              </div>
              <div class="col-md-6 text-right fw-bold">
                €{{ tenant.personalMonthlyIncome }}
              </div>

              <div class="col-md-6" v-if="tenant.nordigen_account_detail">
                {{ $t("Bank owner name") }}
              </div>
              <div
                class="col-md-6 text-right fw-bold"
                v-if="tenant.nordigen_account_detail"
              >
                {{ tenant.nordigen_account_detail.account.ownerName }}
              </div>
              <template v-if="tenant.digid_data">
                <div class="col-md-6">
                  {{ $t("Function address") }}
                </div>
                <div class="col-md-6 text-right fw-bold">
                  {{ tenant.digid_data.address["Functie adres"] }}
                </div>

                <div class="col-md-6">
                  {{ $t("Validity date") }}
                </div>
                <div class="col-md-6 text-right fw-bold">
                  {{ tenant.digid_data.address["Ingangsdatum geldigheid"] }}
                </div>

                <div class="col-md-6">
                  {{ $t("Date registration municipality") }}
                </div>
                <div class="col-md-6 text-right fw-bold">
                  {{ tenant.digid_data.address["Datum inschrijving gemeente"] }}
                </div>
                <div class="col-md-6">
                  {{ $t("Name usage") }}
                </div>
                <div class="col-md-6 text-right fw-bold">
                  {{ tenant.digid_data.personal["Naamgebruik"] }}
                </div>

                <div class="col-md-6">
                  {{ $t("Gender") }}
                </div>
                <div class="col-md-6 text-right fw-bold">
                  {{ $t(tenant.gender) }}
                </div>

                <div class="col-md-6">
                  {{ $t("Place of birth") }}
                </div>
                <div class="col-md-6 text-right fw-bold">
                  {{ tenant.digid_data.personal["Geboorteplaats"] }}
                </div>

                <div class="col-md-6">
                  {{ $t("Country of birth") }}
                </div>
                <div class="col-md-6 text-right fw-bold">
                  {{ tenant.digid_data.personal["Geboorteland"] }}
                </div>

                <div class="col-md-6">
                  {{ $t("Collective income") }}
                </div>
                <div class="col-md-6 text-right fw-bold">
                  <div v-for="(financial, key) in tenant.digid_data.financial" :key="key">
                    {{ financial }}
                  </div>
                </div>

                <template v-if="tenant.profession == 'entrepreneur'">
                  <div class="col-md-6">
                    {{ $t("KvK uittreksel") }}
                  </div>
                  <div
                    class="col-md-6 text-right fw-bold text-info cursor-pointer"
                    @click="download(files.chamber_of_commerce?.id)"
                  >
                    {{ files.chamber_of_commerce?.name }}
                  </div>

                  <div class="col-md-6">
                    {{ $t("Kopie inkomstenbelasting") }}
                  </div>
                  <div
                    class="col-md-6 text-right fw-bold text-info cursor-pointer"
                    @click="download(files.income_tax?.id)"
                  >
                    {{ files.income_tax?.name }}
                  </div>

                  <div class="col-md-6">
                    {{ $t("Winst-en-verlies-rekening") }}
                  </div>
                  <div
                    class="col-md-6 text-right fw-bold text-info cursor-pointer"
                    @click="download(files.profit_and_loss?.id)"
                  >
                    {{ files.profit_and_loss?.name }}
                  </div>

                  <div class="col-md-6">
                    {{ $t("Winst-en-verlies-prognose") }}
                  </div>
                  <div
                    class="col-md-6 text-right fw-bold text-info cursor-pointer"
                    @click="download(files.profit_and_loss_forcast?.id)"
                  >
                    {{ files.profit_and_loss_forcast?.name }}
                  </div>
                </template>
              </template>
            </div>
          </CardV2>
        </div>
        <div class="col-md-6">
          <CardV2>
            <ul class="timeline-parent">
              <template v-for="(history, key) in tenant.history">
                <li
                  class="timeline"
                  v-if="history.key == 'veriff_status' && tenant.veriff_data"
                  :key="key"
                >
                  <span class="check">
                    <i
                      class="fa-sharp fa-solid fa-circle-check text-primary"
                      :class="{
                        'text-primary': tenant.veriff_status == 'success',
                        'text-muted': !tenant.veriff_status != 'success',
                      }"
                    ></i>
                  </span>
                  <div class="fw-bold">
                    {{ $t("Identification - Veriff") }}
                  </div>
                  <div class="text-gray fw-bold fs-14px">
                    {{ history.updated_at | moment("DD-MM-YYYY") }}
                  </div>
                  <p class="text-gray4">
                    {{
                      $t(
                        "Relation has been succesfully identified with Veriff.  Selfie has been matched with identification document."
                      )
                    }}
                  </p>
                  <div class="text-gray4">
                    Name: {{ tenant.veriff_data.verification.person.firstName }}
                    {{ tenant.veriff_data.verification.person.lastName }}
                  </div>
                  <div class="text-gray4">
                    Date of Birth:
                    {{ tenant.veriff_data.verification.person.dateOfBirth }}
                  </div>
                  <div class="text-gray4">
                    Document:
                    {{ tenant.veriff_data.verification.document.number }}
                    {{ tenant.veriff_data.verification.document.type }}
                    {{ tenant.veriff_data.verification.document.country }}
                  </div>
                  <div class="text-gray4">
                    Valid until: {{ tenant.veriff_data.verification.document.validUntil }}
                  </div>
                </li>
                <li
                  class="timeline"
                  v-else-if="history.key == 'digid_data' && tenant.digid_data"
                  :key="key"
                >
                  <span class="check">
                    <i
                      class="fa-sharp fa-solid fa-circle-check"
                      :class="{
                        'text-primary': tenant.digid_verified,
                        'text-muted': !tenant.digid_verified,
                      }"
                    ></i>
                  </span>
                  <div class="fw-bold">
                    {{ $t("Identification - Digid") }}
                  </div>
                  <div class="text-gray fw-bold fs-14px">
                    {{ history.updated_at | moment("DD-MM-YYYY") }}
                  </div>
                  <p class="text-gray4">
                    {{
                      $t(
                        "Relation has been successfully identified with DigiD. The personal details on the left are from MijnOverheid."
                      )
                    }}
                  </p>
                  <BButton
                    v-b-modal.digid-data
                    variant="white"
                    :disabled="getLoadings.cancelAssigning"
                  >
                    {{ $t("View data") }}
                  </BButton>
                </li>
                <li class="timeline" v-else-if="history.key == 'edr_data'" :key="key">
                  <span class="check">
                    <i
                      class="fa-sharp fa-solid fa-circle-check text-primary"
                      :class="{
                        'text-primary': tenant.edr_data,
                        'text-muted': !tenant.edr_data,
                      }"
                    ></i>
                  </span>
                  <div class="fw-bold">
                    {{ $t("Credit check - EDR") }}
                  </div>
                  <div class="text-gray fw-bold fs-14px">
                    {{ history.updated_at | moment("DD-MM-YYYY") }}
                  </div>
                  <p class="text-gray4">
                    {{
                      $t(
                        "Het aangaan van een overeenkomst op basis van krediet wordt verantwoord geacht."
                      )
                    }}
                  </p>
                </li>
                <li
                  class="timeline"
                  v-else-if="history.key == 'personalMonthlyIncome' && tenant.uwv_data"
                  :key="key"
                >
                  <span class="check">
                    <i
                      class="fa-sharp fa-solid fa-circle-check"
                      :class="{
                        'text-primary': tenant.income_checked,
                        'text-muted': !tenant.income_checked,
                      }"
                    ></i>
                  </span>
                  <div class="fw-bold">
                    {{ $t("Income check - MijnUWV - Digid") }}
                  </div>
                  <div class="text-gray fw-bold fs-14px">
                    {{ history.updated_at | moment("DD-MM-YYYY") }}
                  </div>
                  <p class="text-gray4">
                    {{
                      $t(
                        "Relation has shared income details via DigiD login at MijnUWV. The income details can be viewed on the left"
                      )
                    }}
                  </p>
                  <BButton
                    v-b-modal.digid-uwv-data
                    variant="white"
                    :disabled="getLoadings.cancelAssigning"
                  >
                    {{ $t("View data") }}
                  </BButton>
                  <div class="text-gray4 mt-3">Employer: {{ tenant.uwv_data.agency }}</div>
                  <div class="text-gray4 mt-1">
                    Recent income:
                    <ul
                      class="mb-3 mt-2 ml-3"
                      v-for="(income, key2) in tenant.uwv_data.income"
                      :key="key2"
                    >
                      <li class="text-gray list-style-dots-inside">
                        Period: {{ income.period }}
                      </li>
                      <li class="text-gray list-style-dots-inside">
                        Hours: {{ income.hours }}
                      </li>
                      <li class="text-gray list-style-dots-inside">
                        Wages: {{ income.wages }}
                      </li>
                      <li class="text-gray list-style-dots-inside">
                        Car contribution: {{ income.car_contribution }}
                      </li>
                      <li class="text-gray list-style-dots-inside">
                        Value: {{ income.value }}
                      </li>
                    </ul>
                  </div>
                </li>
                <li
                  class="timeline"
                  v-else-if="
                    history.key == 'personalMonthlyIncome' && tenant.nordigen_account_id
                  "
                  :key="key"
                >
                  <span class="check">
                    <i
                      class="fa-sharp fa-solid fa-circle-check"
                      :class="{
                        'text-primary': tenant.income_checked,
                        'text-muted': !tenant.income_checked,
                      }"
                    ></i>
                  </span>
                  <div class="fw-bold">
                    {{ $t("Income check - PSD2 Nordigen") }}
                  </div>
                  <div class="text-gray fw-bold fs-14px">
                    {{ history.updated_at | moment("DD-MM-YYYY") }}
                  </div>
                  <p class="text-gray4">
                    {{
                      $t(
                        "We have verified the income of the relation with a transaction analysis via Nordigen PSD2."
                      )
                    }}
                  </p>
                  <div v-if="tenant?.nordigen_selected_transactions">
                    <div
                      class="text-gray4"
                      v-for="(tr, key) in tenant?.nordigen_selected_transactions || []"
                      :key="key"
                    >
                      {{
                        `${tr.transactionAmount.amount} ${tr.transactionAmount.currency} - ${tr.debtorName}`
                      }}
                    </div>
                  </div>

                  <div v-if="tenant?.selected_transactions">
                    <div
                      class="text-gray4"
                      v-for="(tr, key) in tenant?.selected_transactions || []"
                      :key="key"
                    >
                      {{ `${tr.amount} ${tr.currency} - ${tr.merchant_name}` }}
                    </div>
                  </div>
                </li>
                <li class="timeline" v-else-if="history.key == 'wwft_report'" :key="key">
                  <span class="check">
                    <i
                      class="fa-sharp fa-solid fa-circle-check"
                      :class="{
                        'text-primary': tenant.aml_checked,
                        'text-muted': !tenant.aml_checked,
                      }"
                    ></i>
                  </span>
                  <div class="fw-bold">
                    {{ $t("KYC") }}
                  </div>
                  <div class="text-gray fw-bold fs-14px">
                    {{ history.updated_at | moment("DD-MM-YYYY") }}
                  </div>
                  <p class="text-gray4">
                    {{
                      $t(
                        "The person you searched for does not appear in our partner's compliance resources. These sources therefore do not give rise to an increased risk."
                      )
                    }}
                  </p>
                  <button class="btn btn-white" @click="download()">
                    {{ $t("Download") }}
                  </button>
                </li>
                <!-- <li v-else :key="`${key}_else`">
                  <span class="check">
                    <i class="fa-sharp fa-solid fa-circle-check text-primary"></i>
                  </span>
                  <div class="fw-bold">
                    {{ $t(history.key) }}
                  </div>
                  <div class="text-gray fw-bold fs-14px">
                    {{ history.updated_at | moment("DD-MM-YYYY") }}
                  </div>
                </li> -->
              </template>
            </ul>
          </CardV2>
        </div>
      </div>
    </template>
    <Spinner v-else />

    <DigidDataModal :tenant="tenant" />
  </div>
</template>

<script>
import Spinner from "@/components/ui/Spinner.vue";
import { mapGetters, mapActions } from "vuex";
import CardV2 from "../../../components/general/CardV2.vue";
import DigidDataModal from "./DigidDataModal.vue";

export default {
  name: "ListingAssignedTenantDetail",
  components: {
    Spinner,
    CardV2,
    DigidDataModal,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters("loadingError", ["getLoadings", "getErrors"]),
    ...mapGetters("requestsAndAppointment", ["assignings", "listing", "user"]),
    tenant() {
      return this.user.tenants.find(
        (tenant) => tenant.id == this.$route.params.tenant_id
      );
    },
    files() {
      let files = {};
      for (let f of this.tenant.files) {
        files[f.type] = f;
      }

      return files;
    },
    listingId() {
      return this.$route.params.listingId;
    },
  },
  methods: {
    ...mapActions("requestsAndAppointment", ["cancelAssigning", "getAssignings"]),
    async download(file_id = null) {
      let url = `broker/download/tenant/file/${this.listingId}/${this.tenant.id}`;
      url = file_id ? `${url}/${file_id}` : url;
      const { data } = await window.axios.get(url);
      location.href = data.url;
    },
  },
  created() {
    if (this.listingId != this.listing?.id || !this.listing?.assigned?.user) {
      this.getAssignings({ listingId: this.listingId });
    }
  },
};
</script>

<style lang="scss" scoped>
ul.timeline-parent {
  border-left: 1px solid #e4e4e4;
  position: relative;
}

li.timeline {
  margin-left: 1.5rem;
  margin-bottom: 2.5rem;
}

.check {
  position: absolute;
  left: -0.5rem;
}
</style>
